<template lang="pug">
.main-wrapper.caixas-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(type='button' icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "troco" }'> Configurar Troco</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } caixa`")
                        .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 500px;')

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.$error }")
                                label.form-label Valor:
                                CurrencyInput(v-model="$v.model.nr_valor.$model")
                                .feedback--errors(v-if='submitted && $v.model.$error')
                                .form-message--error(v-if="!$v.model.nr_valor.maxLength") Valor deve ter no máximo 20 dígitos.

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_caixa_supervisor.$error }")
                                label.form-label Caixa Supervisão:
                                ProgressBar(v-if="waitingCaixas" mode="indeterminate")
                                Dropdown(v-else
                                    v-model='model.cd_caixa_supervisor'
                                    :options='options.caixas'
                                    :filter='true'
                                    dataKey='id'
                                    optionLabel='nm_caixa'
                                    optionValue='id'
                                    placeholder='- Selecione -'
                                    @change='model.cd_usuarios = []; attUsuarios()'
                                    )

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_usuarios.$error }")
                                label.form-label Usuário(s):
                                ProgressBar(v-if="waitingFuncionarios" mode="indeterminate")
                                MultiSelect(v-else v-model='model.cd_usuarios' :options='options.funcionarios' filter
                                    dataKey='id' optionLabel='text' optionValue='value' placeholder='- TODOS -'
                                    :disabled='!model.cd_caixa_supervisor')

                            .p-col-6(:class="{ 'form-group--error': submitted && $v.model.dt_vigencia_inicial.$error }")
                                label.form-label Início da vigência:
                                .p-inputgroup
                                    Calendar( v-model='model.dt_vigencia_inicial' dateFormat="dd/mm/yy" :locale="ptbr"
                                        :manualInput='false' :touchUI='windowInnerWidth < 576' ) 
                                    // Button(type='button' icon='jam jam-rubber' @click='model.dt_vigencia_inicial = null;')

                            .p-col-6
                                label.form-label Fim da vigência:
                                .p-inputgroup
                                    Calendar( v-model='model.dt_vigencia_final' dateFormat="dd/mm/yy" :locale="ptbr"
                                        :manualInput='false' :touchUI='windowInnerWidth < 576' )
                                    Button(type='button' icon='jam jam-rubber' @click='model.dt_vigencia_final = null;')

                            .p-col-12.mt-2(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6.aux-2handle
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.mt-2.ta-center
                                Button(label='Salvar' type="submit" style='max-width: 30%')

                
</template>

<style lang="scss">
    .caixas-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }funcionarios_ids
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    // const _ = require('lodash')
    import { Caixas, Funcionario } from './../../middleware'
    import { required, maxLength } from 'vuelidate/lib/validators'
    import moment from "moment"
    import MultiSelect from 'primevue/multiselect'
    import Calendar from 'primevue/calendar'
    import { pCalendarLocale_ptbr } from './../../utils'
    import CurrencyInput from "@/components/CustomComponents/CurrencyInput";


    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, CurrencyInput,
                InputMask, SelectButton, Dropdown, Tooltip, MultiSelect, Calendar
        },
        computed: {
          valorFloat: {
            get() {
              return parseFloat(this.$v.model.nr_valor.$model);
            },
            set() {

            }
          },
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)         
            this.getCaixas()
            if (id != 0) {
                Caixas.findConfigTroco(id).then(response => {
                    if (response.status == 200) {
                        this.model.id = id
                        this.model.cd_caixa_supervisor = response.data.cd_caixa_supervisor.cd_caixa
                        this.getFuncionarios(this.options.caixas.find((caixa) => caixa.id == response.data.cd_caixa_supervisor.cd_caixa).cd_unidades)
                        this.model.cd_usuarios = response.data.cd_usuarios.map(funcionario => (funcionario.id));
                        this.model.nr_valor = +response.data.nr_valor
                        this.model.dt_vigencia_inicial =  moment(response.data.dt_vigencia_inicial)._d
                        this.model.dt_vigencia_final = (response.data.dt_vigencia_final) ? moment(response.data.dt_vigencia_final)._d : null
                        this.model.dt_criado_f = moment(response.data.dt_criado).format("DD/MM/YYYY - HH:mm:ss")
                        this.model.nm_usuario_cri = response.data.nm_usuario_cri
                        if (response.data.dt_atualizado) {
                            this.model.nm_usuario_edi = response.data.nm_usuario_edi
                            this.model.dt_atualizado_f = moment(response.data.dt_atualizado).format("DD/MM/YYYY - HH:mm:ss")
                        }

                    }
                })
                this.waiting = false
            }
        },
        data () {
            return {
                windowInnerWidth: window.innerWidth,
                model: {
                    cd_caixa_supervisor: null,
                    cd_usuarios: null,
                    nr_valor: 0,
                    dt_vigencia_inicial: moment()._d,
                    dt_vigencia_final: null,
                    nm_usuario_cri: '',
                    nm_usuario_edi: '',
                    dt_criado_f: null,
                    dt_atualizado_f: null

                },
                options: {
                    caixas: [],
                    funcionarios: [],
                },
                filters: {
                    caixas_ids: [],
                    funcionarios_ids: [],
                    unidades_medclub: [],
                    dt_vigencia_inicial: moment()._d,
                    dt_vigencia_final: null
                },
                waiting: true,
                waitingForm: false,
                submitted: false,
                waitingCaixas: false,
                waitingFuncionarios: false,
                ptbr: pCalendarLocale_ptbr,
            }
            },
        watch: {
            // "model.cd_caixa_supervisor": function(val) {
            //     // if(val) this.getFuncionarios(this.options.caixas.find((caixa) => caixa.id == val).cd_unidades)
            // }
        },
            validations () {
                let v = {
                    model: {
                        cd_caixa_supervisor: { required },
                        cd_usuarios: { required },
                        nr_valor: { required, maxLength: maxLength(20) },
                        dt_vigencia_inicial: { required },
                    }
                }
                return v
            },
            methods: {
            handleSubmit () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0
                let dataSend = Object.assign({}, this.model)
                dataSend.dt_vigencia_inicial = moment(dataSend.dt_vigencia_inicial).format("YYYY-MM-DDThh:mm:ssZ")
                if(dataSend.id) {
                    dataSend.dt_vigencia_inicial = moment(dataSend.dt_vigencia_inicial).format("DD/MM/YYYY")
                    if(moment(dataSend.dt_vigencia_final)) 
                        dataSend.dt_vigencia_final = moment(dataSend.dt_vigencia_final).format("DD/MM/YYYY")
                }
                this.waitingForm = true
                Caixas.saveConfigTroco(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'troco' })
                        this.$toast.success('Configuração de Troco salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data?.detail) {
                            response.data.detail.forEach((errorMsg, index) => {
                                const duration = Math.max(3000, 3000 + index * 1000);
                                this.$toast.error(errorMsg, { duration });
                            });
                        }else if (response.data) {
                            response.data?.forEach((errorMsg, index) => {
                                const duration = Math.max(3000, 3000 + index * 1000);
                                this.$toast.error(errorMsg?.detail, { duration });
                            });
                        }

                    }
                    this.waitingForm = false
                })
            },
            getFuncionarios (cd_unidades) {
                this.waitingFuncionarios = true
                Funcionario.findAllByUnidade({ cd_unidade_list: cd_unidades.map(i => (i.id)), ie_troco: true }).then(response => {
                    this.waitingFuncionarios = false
                    if (response.status === 200) {
                        this.options.funcionarios = response.data.map(funcionario => {
                            return { text: funcionario.username, value: funcionario.id }
                        });
                    }
                })
            },
            getCaixas() {
                this.waitingCaixas = true
                Caixas.findAll({ie_tipo: 'S', ie_usuario: true}).then(response => {
                    this.waitingCaixas = false
                    if (response.status === 200) {
                        this.options.caixas = response.data
                        for(let caixa in response.data){
                            if(this.filters.unidades_medclub.length)
                                this.filters.unidades_medclub.push(...response.data[caixa].cd_unidades_medclub)
                        }
                    }
                    this.waiting = false
                })
            },
            convertToFloat(value) {
                return parseFloat(value);
            },
            attUsuarios(){
                if(this.model.cd_caixa_supervisor) 
                    this.getFuncionarios(this.options.caixas.find((caixa) => caixa.id == this.model.cd_caixa_supervisor).cd_unidades)
            }
            // applyFilters (page) {
            //     this.paginator.page = page || 1
            //     if (this.$route.query.pg) this.paginator.page = this.$route.query.pg
            //     let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
            //     Object.keys(this.filters).forEach((key) => {
            //         if (key.substr(0, 3) == 'dt_') {
            //             if (this.filters[key]) params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
            //         } else if (this.filters[key]) params[key] = this.filters[key]
            //     })
            //     // Fake loading quando os filtros forem os mesmos
            //     if (_.isEqual(this.params, params)) {
            //         if (!this.waiting) {
            //             this.waiting = true
            //             setTimeout(() => this.waiting = false, 300)
            //         }
            //     } else {
            //         this.params = Object.assign({}, params)
            //         this.waiting = true
            //         this.getListDebounce(params, this.getList)
            //     }
            // },
            // limparData(key) {
            //     this.filters[key] = moment()._d
            //     if (this.filters.dt_fim < this.filters.dt_inicio) {
            //         this.filters['dt_fim' !== key && 'dt_fim' || 'dt_inicio'] = moment()._d
            //     }
            // },
        }
      }

</script>
